import type { SerializeFrom } from "@remix-run/node";
import type { FetcherWithComponents } from "@remix-run/react";
import { useFetcher, useNavigation } from "@remix-run/react";
import { useEffect, useState } from "react";

import type {
  RequestErrorResult,
  RequestResult,
  RequestSuccessResult,
} from "~/utils/request-helpers.server";

function useFetcherState<TData>(
  fetcher: FetcherWithComponents<SerializeFrom<RequestResult<TData>>>,
) {
  const isSubmitting = fetcher.state === "submitting";
  const isLoading = fetcher.state === "loading";
  const isFetching = isSubmitting || isLoading;
  const isSuccess =
    fetcher.state === "idle" && fetcher.data && (fetcher.data as RequestSuccessResult<TData>).ok;
  const isError =
    fetcher.state === "idle" && fetcher.data && !!(fetcher.data as RequestErrorResult)?.error;
  const error = (fetcher.data as RequestErrorResult)?.error;
  return { isSubmitting, isLoading, isFetching, isSuccess, isError, error };
}

type UseWtFetcher<TData> = {
  onSuccess?: (response: RequestSuccessResult<TData>) => any;
  onError?: (response: RequestErrorResult) => any;
};

function useErrorSubscription({
  fetcher,
  onError,
}: {
  fetcher: any;
  onError: UseWtFetcher<unknown>["onError"];
}) {
  const { state: navigationState } = useNavigation();
  const [isFresh, setIsFresh] = useState(true);

  useEffect(() => {
    if (!isFresh) return;
    if (navigationState !== "idle") return;
    if (fetcher.state !== "idle") return;
    if (!fetcher.data?.error) return;
    setIsFresh(false);
    onError?.(fetcher.data);
  }, [fetcher.data, fetcher.state, isFresh, onError, navigationState]);

  useEffect(() => {
    if (isFresh) return;
    if (fetcher.state === "idle") return;
    setIsFresh(true);
  }, [fetcher.state, isFresh]);
}

function useSuccessSubscription<TData>({
  fetcher,
  onSuccess,
}: {
  fetcher: any;
  onSuccess: UseWtFetcher<TData>["onSuccess"];
}) {
  const { state: navigationState } = useNavigation();
  const [isFresh, setIsFresh] = useState(true);

  useEffect(() => {
    if (!isFresh) return;
    if (navigationState !== "idle") return;
    if (fetcher.state !== "loading") return;
    if (fetcher.data?.error) return;
    if (!fetcher.data) return;
    setIsFresh(false);
    onSuccess?.(fetcher.data);
  }, [fetcher.data, fetcher.state, isFresh, onSuccess, navigationState]);

  useEffect(() => {
    if (isFresh) return;
    if (fetcher.state === "idle") return;
    if (fetcher.state === "loading") return;
    setIsFresh(true);
  }, [fetcher.state, isFresh]);
}

export function useWtFetcher<TData>({ onError, onSuccess }: UseWtFetcher<TData> = {}) {
  const fetcher = useFetcher<TData>();

  const state = useFetcherState<TData>(fetcher);

  useErrorSubscription({ fetcher, onError });
  useSuccessSubscription<TData>({ fetcher, onSuccess });

  return [fetcher, state] as const;
}
